export default {
  namespaced: true,
  state: {
    login: {
      admin_name: '',
      admin_id: '',
      login_token: '',
      login_status: false,
    },
  },
  mutations: {
    LOGIN(state, payload) {
      state.login.admin_name = payload.adminName;
      state.login.admin_id = payload.adminId;
      state.login.login_status = payload.loginStatus;
      state.login.login_token = payload.loginToken;
    },
  },
  actions: {
    /* 儲存登入狀態 */
    async save_loginstatus(context, login) {
      context.commit('LOGIN', login);
      localStorage.setItem('gping_admin', login.loginToken);
      localStorage.setItem('gping_adminId', login.adminId);
    },
  },
  getters: {
    login(state) {
      return state.login;
    },
  },
};
