import Vue from 'vue';
import axios from 'axios';
import { data } from 'jquery';
import router from '../router';

/* admin 管理員 */
const adminRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/admin`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* system 系統 */
const systemRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/system`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* dashboard */
const dashboardRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/site/dashboard`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* banner */
const bannerRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/site/banner`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

const bannerFormRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/site/banner`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    'Content-Type': 'multipart/form-data',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* 首頁區塊設定  */
const blockRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/site/block`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* product 商品 */
const productRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/product`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* product category */
const productCategoryRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/product`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    'Content-Type': 'multipart/form-data',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* product image */
const productImgRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/product/images`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    'Content-Type': 'multipart/form-data',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});
/* product article image */
const productArticleRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/product/images/article`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    'Content-Type': 'multipart/form-data',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* order 訂單 */
const orderRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/ordercustomer`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* orderV2 訂單V2 */
const orderV2Request = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/ordercustomerV2`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* 會員 */
const memberRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/customer`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* 購物車 */
const cartRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/cart`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    cartSerial: {
      toString() {
        return `${localStorage.getItem('cart_serial_admin')}`;
      },
    },
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* 倉儲 */
const warehouseRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/depot`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* 業績 */
const performanceRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/performance`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* 消息 */
const newsRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/news`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});
/* 消息 form-data */
const newsImageRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/news`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    'Content-Type': 'multipart/form-data',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* 門市 */
const storeRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/store`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});
/* 門市 form-data */
const storeImageRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/store`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    'Content-Type': 'multipart/form-data',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* 優惠碼 */
const couponcodeRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/coupon`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

/* 優惠活動 */
const saleEventRequest = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH}/m/saleevent`,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    system: 'MG',
    platform: 'manage',
    Authorization: {
      toString() {
        return `bearer ${localStorage.getItem('gping_admin')}`;
      },
    },
  },
});

const allRequest = [
  adminRequest,
  systemRequest,
  dashboardRequest,
  bannerRequest,
  bannerFormRequest,
  blockRequest,
  productRequest,
  productCategoryRequest,
  productImgRequest,
  productArticleRequest,
  orderRequest,
  orderV2Request,
  memberRequest,
  cartRequest,
  warehouseRequest,
  performanceRequest,
  newsRequest,
  newsImageRequest,
  storeRequest,
  storeImageRequest,
  couponcodeRequest,
  saleEventRequest,
];
allRequest.forEach((item) => {
  item.interceptors.response.use(
    (response) => Promise.resolve(response),
    (err) => {
      if (err && err.response) {
        let error = '';
        let errorText = '';
        switch (err.response.status) {
          case 400:
            error = err.response.data;
            if (error.code === '9998') {
              for (const errorKey of Object.values(error.data)) {
                errorText += `<p>${errorKey}</p>`;
              }
              Vue.swal({
                icon: 'error',
                title: '錯誤!',
                html: errorText,
                confirmButtonText: '關閉視窗',
              });
            }
            if (error.code === '9999') {
              Vue.swal({
                icon: 'error',
                title: '錯誤!',
                text: error.text,
                confirmButtonText: '關閉視窗',
              });
            }
            break;
          case 401:
            localStorage.removeItem('gping_admin');
            localStorage.removeItem('gping_adminId');
            Vue.swal({
              icon: 'error',
              title: '錯誤!',
              text: '未登入或無權限',
              timer: 1000,
              confirmButtonText: '關閉視窗',
            });
            router.push('/login');
            break;
          case 403:
            Vue.swal({
              icon: 'error',
              title: '錯誤!',
              text: '未登入或無權限',
              timer: 1000,
              confirmButtonText: '關閉視窗',
            });
            router.push('/login');
            break;
          case 404:
            Vue.swal({
              icon: 'error',
              title: '錯誤!',
              text: '找不到該頁面',
              confirmButtonText: '關閉視窗',
            });
            break;
          case 500:
            Vue.swal({
              icon: 'error',
              title: '錯誤!',
              text: '伺服器出錯，請洽技術人員',
              confirmButtonText: '關閉視窗',
            });
            break;
          case 503:
            Vue.swal({
              icon: 'error',
              title: '錯誤!',
              text: '伺服器出錯，請洽技術人員',
              confirmButtonText: '關閉視窗',
            });
            break;
          default:
            // Vue.swal({
            //   icon: 'error',
            //   title: '錯誤!',
            //   text: `連接錯誤${err.response.status}`,
            //   confirmButtonText: '關閉視窗',
            // });
        }
      } else {
        Vue.swal({
          icon: 'error',
          title: '錯誤!',
          text: '連接到服務器失敗',
          confirmButtonText: '關閉視窗',
        });
      }
      return Promise.resolve(err.response);
    },
  );
});

export const API_ADMIN_LOGIN = (data) => adminRequest.post('/login', data); /* 管理員登入 */
export const API_GET_LOGINSTATUS = (adminId = '') => adminRequest.get(`/${adminId}`); /* 取得登入狀態 */
export const API_CREATE_ADMIN = (data) => adminRequest.post('', data); /* 新增管理者 */
export const API_UPDATE_ADMIN = (data) => adminRequest.put('', data); /* 更新管理者 */
export const API_DELETE_ADMIN = (adminId) => adminRequest.delete(`/${adminId}`); /* 刪除管理者 */
export const API_UPDATE_ADMIN_PWD = (newPassword) => adminRequest.put(`/password/${newPassword}`); /* 更新管理者密碼 */
export const API_GET_GROUP_AUTHORITY = (groupCode) => adminRequest.get(`/group/${groupCode}`); /* 取得群組權限 */
export const API_ADD_GROUP_AUTHORITY = (data) => adminRequest.post('/group', data); /* 新增群組權限 */
export const API_UPDATE_GROUP_AUTHORITY = (data) => adminRequest.put('/group', data); /* 更新群組權限 */
export const API_DELETE_GROUP_AUTHORITY = (groupCode) => adminRequest.delete(`/group/${groupCode}`); /* 刪除群組權限 */

export const API_GET_SYSTEM = () => systemRequest.get(); /* 取得系統列表 */
export const API_GET_SYSTEM_OPTION = () => systemRequest.get('/option'); /* 取得系統選項集合 */

export const API_GET_DASHBOARD = (data) => dashboardRequest.post('', data); /* 取得 dashboard 資料 */

export const API_GET_BANNER = (systemCode, optionHide) => bannerRequest.get(`/${systemCode}/${optionHide}`); /* 取得廣告牆列表 */
export const API_DELETE_BANNER = (serialNumber, optionHide) => bannerRequest.delete(`/${serialNumber}/${optionHide}`); /* 刪除廣告牆 */
export const API_ADD_BANNER = (data) => bannerFormRequest.post('', data); /* 新增廣告牆 */
export const API_UPDATE_BANNER = (data) => bannerFormRequest.put('', data); /* 更新廣告牆 */
export const API_UPDATE_BANNER_ORDER = (data) => bannerRequest.post('/sort', data); /* 更新廣告牆排序 */

export const API_GET_BLOCKS = (systemCode, optionHide) => blockRequest.get(`/${systemCode}/${optionHide}`); /* 取得首頁區塊 */
export const API_ADD_BLOCK = (data) => blockRequest.post('', data); /* 新增首頁區塊 */
export const API_UPDATE_BLOCK = (data) => blockRequest.put('', data); /* 更新首頁區塊 */
export const API_DELETE_BLOCK = (blockSerial, optionHide) => blockRequest.delete(`/${blockSerial}/${optionHide}`); /* 刪除首頁區塊 */
export const API_UPDATE_BLOCK_ORDER = (data) => blockRequest.post('/sort', data); /* 更新首頁區塊排序 */

export const API_GET_PRODUCT_CATEGORY = (systemCode) => productRequest.get(`/${systemCode}/list/category`); /* 取得主次分類列表 */
export const API_GET_CATEGORY_DETAIL = (categoryCode) => productRequest.get(`/category/${categoryCode}`); /* 取得分類細節 */
export const API_ADD_MAIN_CATEGORY = (data) => productCategoryRequest.post('/category', data); /* 建立主分類 */
export const API_UPDATE_MAIN_CATEGORY = (data) => productCategoryRequest.put('/category', data); /* 更新主分類 */
export const API_ADD_SUB_CATEGORY = (data) => productCategoryRequest.post('/categorySub', data); /* 建立次分類 */
export const API_UPDATE_SUB_CATEGORY = (data) => productCategoryRequest.put('/categorySub', data); /* 更新次分類 */
export const API_UPDATE_CATEGORY_SHOW = (data) => productRequest.put('/category/showfront', data); /* 更新分類前台顯示 */
export const API_UPDATE_CATEGORY_ORDER = (data) => productRequest.put('/category/sort', data); /* 更新分類順序 */
export const API_DELETE_CATEGORY = (categoryCode) => productRequest.delete(`/category/${categoryCode}`); /* 刪除分類 */
export const API_GET_PRODUCT_LIST = (categorySubCode) => productRequest.get(`/list/categorySub/${categorySubCode}`); /* 取得商品列表 */
export const API_GET_PRODUCT_SEARCH = (data) => productRequest.post('/search', data); /* 搜尋商品列表 */
export const API_UPDATE_PRODUCT_ORDER = (data) => productRequest.put('/sort', data); /* 更新商品順序 */
export const API_CREATE_PRODUCT = (data) => productRequest.post('', data); /* 建立商品 */
export const API_UPDATE_PRODUCT = (data) => productRequest.put('', data); /* 更新商品 */
export const API_GET_PRODUCT_DETAIL = (prodSerial) => productRequest.get(`/${prodSerial}`); /* 取得商品細節 */
export const API_ADD_PRODUCT_IMAGE = (data) => productImgRequest.post('', data); /* 新增商品圖片 */
export const API_UPLOAD_PRODUCT_ARTICLE_IMAGE = (data) => productArticleRequest.post('', data); /* 上傳文章圖片 */
export const API_UPDATE_PRODUCT_IMAGE = (data) => productRequest.put('/images', data); /* 更新圖片檔名 */
export const API_DELETE_PRODUCT_IMAGE = (data) => productRequest.delete('/images', { data }); /* 更新圖片檔名 */
export const API_UPDATE_PRODUCT_SHOW = (data) => productRequest.put('/option', data); /* 更新商品前台顯示 */

export const API_GET_ALL_LABEL = () => productRequest.get('/label'); /* 取得標籤列表 */

export const API_GET_ORDER_LIST = (data) => orderRequest.post('', data); /* 取得訂單列表 */
export const API_GET_ORDER_DETIAL = (orderSerial) => orderRequest.get(`/${orderSerial}`); /* 取得訂單細節 */
export const API_CANCEL_ORDER = (orderSerial) => orderRequest.delete(`/${orderSerial}`); /* 取消訂單 */
export const API_UPDATE_ORDER = (data) => orderRequest.put('', data); /* 更新收件資料 */
export const API_UPDATE_ORDER_RECEIPT = (data) => orderRequest.put('/receipt', data); /* 更新收件資料 */
export const API_UPDATE_ORDER_BILL = (data) => orderRequest.put('/bill/item', data); /* 設定帳款付款 */
export const API_UPDATE_ORDER_RETURN_ITEM = (data) => orderRequest.put('/return/item', data); /* 商品退貨 */

export const API_GET_ORDER_LIST_V2 = (data) => orderV2Request.post('', data); /* 取得訂單列表V2 */

export const API_GET_MEMBER = (data) => memberRequest.post('/search', data); /* 取得會員資料列表 */
export const API_GET_MEMBER_DETAIL = (userId) => memberRequest.get(`/${userId}`); /* 取得會員細節 */
export const API_UPDATE_MEMBER = (data) => memberRequest.put('', data); /* 更新會員資料 */
export const API_REGISTER_MEMBER = (data) => memberRequest.post('', data); /* 註冊會員 */
export const API_RESET_PASSWORD = (userId) => memberRequest.get(`/password/${userId}`); /* 重設密碼 */
export const API_UPDATE_MEMBER_GPOINT = (data) => memberRequest.post('/gpoint', data); /* 更新G幣 */

export const API_INIT_CART = (data) => cartRequest.post('', data); /* 初始化購物車 */
export const API_GET_CART = () => cartRequest.get(''); /* 初始化購物車 */
export const API_ADDTO_CART = (data) => cartRequest.post('/item', data); /* 加入購物車 */
export const API_UPDATE_CART = (data) => cartRequest.put('/item', data); /* 更新購物車 */
export const API_GET_CART_CATEGORY = (systemCode) => cartRequest.get(`/${systemCode}`); /* 取得主次分類資料 */
export const API_GET_CART_PRODUCT_LIST = (categorySubCode) => cartRequest.get(`/category/${categorySubCode}`); /* 取得商品列表 */
export const API_DELETE_CART = (data) => cartRequest.delete('/item', { data }); /* 刪除購物車商品 */
export const API_ADD_DISCOUNT = (serviceDiscount) => cartRequest.post(`/discount/${serviceDiscount}`); /* 加入客服優惠 */
export const API_SET_NOTRANSFEE = () => cartRequest.post('/noTransFee'); /* 免運設定 */
export const API_CANCEL_NOTRANSFEE = () => cartRequest.delete('/noTransFee'); /* 取消免運設定 */
export const API_SET_OPTION = (data) => cartRequest.post('/option', data); /* 設定購物車選項 */
export const API_SET_FUGUI_TIME = (arriveDate) => cartRequest.put(`/fugui/arrive/${arriveDate}`); /* 設定福貴糕到貨時間 */
export const API_POST_ORDER = (data) => cartRequest.post('/confirm', data); /* 送出訂單 */

export const API_GET_WAREHOUSE_LIST = () => warehouseRequest.get(''); /* 取得倉庫列表 */
export const API_GET_WAREHOUSE_DETAIL = (depotCode) => warehouseRequest.get(`/${depotCode}`); /* 取得倉庫資料 */
export const API_ADD_WAREHOUSE = (data) => warehouseRequest.post('', data); /* 新增倉庫 */
export const API_UPDATE_WAREHOUSE = (data) => warehouseRequest.put('', data); /* 更新倉庫 */
export const API_DELETE_WAREHOUSE = (depotCode) => warehouseRequest.delete(`/${depotCode}`); /* 刪除倉庫 */
export const API_GET_WAREHOUSE_CATEGORY_LIST = () => warehouseRequest.get('/category'); /* 取得商品分類列表 */
export const API_GET_STORAGE_LIST = (categorySubCode) => warehouseRequest.get(`/storage/${categorySubCode}`); /* 取得庫存列表 */
export const API_UPDATE_STORAGE = (data) => warehouseRequest.put('/storage', data); /* 更新庫存安全量 */
export const API_GET_PRODUCT_BOM = (data) => warehouseRequest.post('/storage/package', data); /* 取得組合商品BOM */
export const API_UPDATE_PRODUCT_BOM = (data) => warehouseRequest.put('/storage/package', data); /* 更新組合商品BOM */

export const API_GET_PERFORMANCE_ORDER = (data) => performanceRequest.post('/order', data); /* 業績訂單查詢 */
export const API_GET_PERFORMANCE_ORDERPERDAY = (data) => performanceRequest.post('/orderPerDay', data); /* 業績訂單分日查詢 */
export const API_GET_PERFORMANCE_CATEGORY = (data) => performanceRequest.post('/category', data); /* 業績分類查詢 */
export const API_GET_PERFORMANCE_BRAND = (data) => performanceRequest.post('/brand', data); /* 業績品牌查詢 */
export const API_GET_PERFORMANCE_GROUP = (data) => performanceRequest.post('/group', data); /* 業績分眾查詢 */
export const API_GET_PERFORMANCE_KEYWORD = (data) => performanceRequest.post('/keyword', data); /* 業績關鍵字查詢 */
export const API_GET_PERFORMANCE_SALESEVENT = (data) => performanceRequest.post('/salesEvent', data); /* 業績優惠活動查詢 */
export const API_GET_PERFORMANCE_COUPON = (data) => performanceRequest.post('/coupon', data); /* 業績兌換活動查詢 */

export const API_GET_NEWS_CATEGORY = () => newsRequest.get('/category'); /* 取得消息分類 */
export const API_GET_NEWS_LIST = (categoryCode) => newsRequest.get(`/category/${categoryCode}/list`); /* 取得消息分類 */
export const API_GET_NEWS_DETAIL = (newsSerial) => newsRequest.get(`/${newsSerial}`); /* 取得消息細節 */
export const API_UPDATE_NEWS = (data) => newsRequest.put('', data); /* 更新消息 */
export const API_CREATE_NEWS = (data) => newsRequest.post('', data); /* 建立消息 */
export const API_DELETE_NEWS = (newsSerial) => newsRequest.delete(`/${newsSerial}`); /* 刪除消息 */
export const API_UPLOAD_NEWSIMAGE = (data) => newsImageRequest.post('/images', data); /* 上傳消息圖片 */
export const API_UPLOAD_NEWSIMAGE_ARTICLE = (data) => newsImageRequest.post('/images/article', data); /* 上傳內文圖片 */

export const API_GET_STORE_CATEGORY = (systemCode) => storeRequest.get(`/${systemCode}/category`); /* 取得門市分類 */
export const API_GET_STORE_LIST = (categorySubCode) => storeRequest.get(`/categorySub/${categorySubCode}/list`); /* 取得門市列表 */
export const API_GET_STORE_DETAIL = (storeCode) => storeRequest.get(`/${storeCode}`); /* 取得門市資料 */
export const API_CREATE_STORE = (data) => storeRequest.post('', data); /* 新增門市資料 */
export const API_UPDATE_STORE = (data) => storeRequest.put('', data); /* 更新門市資料 */
export const API_DELETE_STORE = (storeCode) => storeRequest.delete(`${storeCode}`); /* 刪除門市資料 */
export const API_UPDATE_STORE_ORDER = (data) => storeRequest.put('/sort', data); /* 更新門市順序 */
export const API_UPLOAD_STOREIMAGE = (data) => storeImageRequest.post('/images', data); /* 更新門市順序 */

export const API_GET_COUPONCODE_LIST = () => couponcodeRequest.get(); /* 取得優惠碼列表 */
export const API_GET_COUPONCODE_DETAIL = (couponSerial) => couponcodeRequest.get(`/${couponSerial}`); /* 取得優惠碼細節 */
export const API_CREATE_COUPON = (data) => couponcodeRequest.post('', data); /* 新增優惠資料 */
export const API_UPDATE_COUPON = (data) => couponcodeRequest.put('', data); /* 更新優惠資料 */
export const API_DELETE_COUPON = (couponSerial) => couponcodeRequest.delete(`/${couponSerial}`); /* 刪除優惠資料 */

export const API_GET_SALEEVENT_CATEGORY = () => saleEventRequest.get('/category'); /* 取得優惠分類 */
export const API_GET_SALEEVENT_LIST = () => saleEventRequest.get(''); /* 取得優惠列表 */
export const API_GET_SALEEVENT_DETAIL = (saleEventCode) => saleEventRequest.get(`/${saleEventCode}`); /* 取得優惠列表 */
export const API_CREATE_SALEEVENT = (categoryCode, data) => saleEventRequest.post(`/${categoryCode}`, data); /* 新增優惠活動 */
export const API_UPDATE_SALEEVENT = (categoryCode, data) => saleEventRequest.put(`/${categoryCode}`, data); /* 新增優惠活動 */
export const API_DELETE_SALEEVENT = (saleEventCode) => saleEventRequest.delete(`/${saleEventCode}`); /* 刪除優惠活動 */
