import Vue from 'vue';
import VueRouter from 'vue-router';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/Login.vue'),
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/dashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'news',
        name: 'News',
        component: () => import('@/views/news.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'category',
        name: 'Category',
        component: () => import('@/views/category.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'product',
        name: 'Product',
        component: () => import('@/views/product.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'indexboard',
        name: 'Indexboard',
        component: () => import('@/views/indexboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'saleevent',
        name: 'saleevent',
        component: () => import('@/views/saleevent.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'performance',
        name: 'Performance',
        component: () => import('@/views/performance.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'orders',
        name: 'Orders',
        component: () => import('@/views/orders.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'stock',
        name: 'Stock',
        component: () => import('@/views/stock.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'store',
        name: 'Store',
        component: () => import('@/views/store.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'member',
        name: 'Member',
        component: () => import('@/views/member.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'toorder',
        name: 'Tember',
        component: () => import('@/views/toorder.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'system',
        name: 'System',
        component: () => import('@/views/system.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'statistics',
        name: 'Statistics',
        component: () => import('@/views/statistics.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'couponcode',
        name: 'Couponcode',
        component: () => import('@/views/couponcode.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
