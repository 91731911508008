import {
  API_INIT_CART,
  API_GET_CART,
  API_ADDTO_CART,
  API_UPDATE_CART,
  API_DELETE_CART,
} from '@/plugins/api';
import detectDevice from '@/plugins/detect';

export default {
  namespaced: true,
  state: {
    cart_serial_admin: '',
    cart: {
      condition: {
        arriveDate: [],
      },
      info: {},
      items: [],
      pricing: {},
      pur: {},
      rec: {},
      receipt: {},
    } /* 購物車內容 */,
  },
  mutations: {
    CART_SERIAL_ADMIN(state, payload) {
      state.cart_serial_admin = payload;
    },
    CART(state, payload) {
      state.cart = payload;
    },
  },
  actions: {
    async get_cart_serial(context, userId) {
      /* 瀏覽器、作業系統、解析度資料 */
      const detect = detectDevice;
      const data = {
        userId,
        os: detect.os,
        browser: detect.browser,
        resolution: detect.resolution,
      };
      let response;
      let res;
      try {
        response = await API_INIT_CART(data);
        res = response.data;
        // console.log('取得購物車代碼 成功', res);
        localStorage.setItem('cart_serial_admin', res.data);
        context.commit('CART_SERIAL_ADMIN', res.data);
      } catch (error) {
        // console.log('取得購物車代碼 錯誤', error.response);
      }
    },
    /* 取得購物車內容 */
    async get_cart(context) {
      let response;
      let res;
      try {
        response = await API_GET_CART();
        res = response.data;
        // console.log('取得購物車內容 成功', res);
        const tempData = res.data;
        if (tempData.items.length > 0) {
          const orderData = tempData.items.filter(
            (item) => item.prodMode === '購買品'
              || item.prodMode === '贈品'
              || item.prodMode === '加購品'
              || item.priceType === '優惠價'
              || item.priceType === '活動折扣價',
          );
          const transfeeData = tempData.items.filter(
            (item) => item.prodMode === '運費',
          );
          const couponData = tempData.items.filter(
            (item) => item.prodMode === '折價卷' || item.priceType === '贈品價',
          );
          tempData.transfee = transfeeData;
          tempData.coupon = couponData;
          tempData.items = orderData;
        }
        context.commit('CART', tempData);
      } catch (error) {
        // console.log('取得購物車內容 錯誤', error.response);
        localStorage.removeItem('cart_serial_admin');
      }
    },
    /* 商品加入購物車 */
    async addto_cart(context, { product, qty }) {
      let response;
      let res;
      const data = {
        prodSerial: product.prodSerial,
        fromProdSerial: '',
        type: product.type,
        mode: '購買品',
        isReturnCart: true,
        quantity: qty,
        deliveryDays: 0,
        deliveryTimes: 1,
      };
      try {
        response = await API_ADDTO_CART(data);
        res = response.data;
        // console.log('商品加入購物車 成功', res);
        if (res.code === '0000') {
          context.dispatch('get_cart');
        }
      } catch (error) {
        // console.log('商品加入購物車 錯誤', error.response);
      }
    },
    /* 更新購物車商品 */
    async update_cart(context, { product }) {
      let response;
      let res;
      try {
        response = await API_UPDATE_CART(product);
        res = response.data;
        // console.log('更新購物車商品 成功', res);
        if (res.code === '0000') {
          context.dispatch('get_cart');
        }
      } catch (error) {
        // console.log('更新購物車商品 錯誤', error.response);
      }
    },
    /* 刪除購物車內容 */
    async delete_cart(context, product) {
      const data = {
        prodSerial: product.prodSerial,
        fromProdSerial: '',
        type: '',
        mode: product.prodMode,
        isReturnCart: true,
        quantity: 0,
        deliveryDays: 0,
        deliveryTimes: 0,
      };
      try {
        await API_DELETE_CART(data);
        // console.log('刪除購物車內容 成功');
      } catch (error) {
        // console.log('刪除購物車內容 錯誤', error.response);
      }
    },
  },
  getters: {
    cart_serial_admin(state) {
      return state.cart_serial_admin;
    },
    cart(state) {
      return state.cart;
    },
    pur_profile(state) {
      return state.cart.pur;
    },
    rec_profile(state) {
      return state.cart.rec;
    },
  },
};
