import {
  API_GET_SYSTEM,
  API_GET_SYSTEM_OPTION,
  API_GET_ALL_LABEL,
} from '@/plugins/api';

export default {
  namespaced: true,
  state: {
    system: [] /* 系統列表 */,
    nonpoint_payment: [] /* 付款方式 - 非購物金 */,
    label: [] /* 標籤列表 */,
    option: {
      system: [],
    } /* 後台各選項 */,
  },
  mutations: {
    SYSTEM(state, payload) {
      state.system = payload;
    },
    NONPOINT_PAYMENT(state, payload) {
      state.nonpoint_payment = payload;
    },
    LABEL(state, payload) {
      state.label = payload;
    },
    OPTION(state, payload) {
      state.option = payload;
    },
  },
  actions: {
    /* 取得系統列表 */
    async get_system(context) {
      const response = await API_GET_SYSTEM();
      const res = response.data;
      // console.log('取得系統列表 成功', res);
      if (res.code === '0000') {
        context.commit('SYSTEM', res.data);
      }
    },
    /* 取得後台各選項 */
    async get_system_option(context) {
      const response = await API_GET_SYSTEM_OPTION();
      const res = response.data;
      // console.log('取得各選項', res);
      if (res.code === '0000') {
        context.commit('OPTION', res.data);
      }
    },
    /* 取得付款方式 - 非購物金 */
    async get_nonpoint_payment(context) {
      const response = await API_GET_SYSTEM_OPTION();
      const res = response.data;
      // console.log('取得各選項', res);
      // console.log('取得付款方式 - 非購物金 成功', res.data.nonPointPayment);
      if (res.code === '0000') {
        context.commit('NONPOINT_PAYMENT', res.data.nonPointPayment);
      }
    },
    /* 取得標籤列表 */
    async get_all_label(context) {
      const response = await API_GET_ALL_LABEL();
      const res = response.data;
      // console.log('取得標籤列表 成功', res.data);
      if (res.code === '0000') {
        context.commit('LABEL', res.data);
      }
    },
  },
  getters: {
    system(state) {
      return state.system;
    },
    nonpoint_payment(state) {
      return state.nonpoint_payment;
    },
    label(state) {
      return state.label;
    },
    option(state) {
      return state.option;
    },
  },
};
