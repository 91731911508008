<template>
  <div
    id="app"
    :class="{ main_sidebar_status, 'sidebar_open': main_sidebar_status, 'sidebar_closed': !main_sidebar_status, 'sidebar_collapse': !main_sidebar_status }"
    @click="close_sidebar()"
  >
    <loading :active="pageLoading" />
    <router-view />
    <!-- <a
      v-if="is_active"
      href="#"
      class="go_top"
      @click.prevent="go_top()"
    >
      <i class="icon-chevron-thin-up" />
    </a> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  API_GET_LOGINSTATUS,
} from '@/plugins/api';

const autoCollapseSize = 992;

export default {
  data() {
    return {
      is_active: false,
    };
  },
  computed: {
    ...mapGetters(['pageLoading', 'main_sidebar_status']),
    ...mapGetters('admin', ['login']),
  },
  async mounted() {
    const vm = this;
    vm.update_device();
    $(window).resize(() => {
      vm.update_device();
    });
    await vm.check_loginstatus();
    // window.addEventListener('scroll', vm.handleScroll);
  },
  methods: {
    handleScroll() {
      const vm = this;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 0 && !vm.is_active) {
        vm.is_active = true;
      } else if (scrollTop === 0) {
        vm.is_active = false;
      }
    },
    /* 登入驗證 */
    async check_loginstatus() {
      const vm = this;
      const adminId = localStorage.getItem('gping_adminId') || '';
      const response = await API_GET_LOGINSTATUS();
      const res = response.data;
      if (res.code === '0000') {
        const adminInfo = res.data.find((item) => item.adminId === adminId);
        vm.$store.dispatch('admin/save_loginstatus', {
          adminName: adminInfo.adminName,
          adminId: adminInfo.adminId,
          loginStatus: true,
          loginToken: localStorage.getItem('gping_admin'),
        });
      }
    },
    /* go top */
    async go_top() {
      $('html, body').animate({
        scrollTop: 0,
      }, 300);
    },
    /* 檢測裝置寬度 */
    async update_device() {
      const vm = this;
      const wdth = $(window).width();
      if (wdth < autoCollapseSize) {
        vm.$store.dispatch('toggle_sidebar', false);
      } else if (wdth >= autoCollapseSize) {
        vm.$store.dispatch('toggle_sidebar', true);
      }
    },
    async close_sidebar() {
      const vm = this;
      const wdth = $(window).width();
      if (wdth < autoCollapseSize) {
        vm.$store.dispatch('toggle_sidebar', false);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/all.scss';
</style>
