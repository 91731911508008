import Vue from 'vue';
import Vuex from 'vuex';

import admin from './admin';
import system from './system';
import cart from './cart';
import product from './product';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true, // 嚴謹模式
  state: {
    pageLoading: false /* 等待動畫 */,
    now_page: '',
    main_sidebar_status: false, /* true : 打開側邊選單; false: 收合側邊選單 */
  },
  mutations: {
    PAGELOAD(state, payload) {
      state.pageLoading = payload;
    },
    NOWPAGE(state, payload) {
      state.now_page = payload;
    },
    MAIN_SIDEBAR_STATUS(state, payload) {
      state.main_sidebar_status = payload;
    },
  },
  actions: {
    async page_load(context, status) {
      context.commit('PAGELOAD', status);
    },
    async now_page(context, page) {
      context.commit('NOWPAGE', page);
    },
    async toggle_sidebar(context, status) {
      context.commit('MAIN_SIDEBAR_STATUS', status);
    },
  },
  getters: {
    pageLoading(state) {
      return state.pageLoading;
    },
    now_page(state) {
      return state.now_page;
    },
    main_sidebar_status(state) {
      return state.main_sidebar_status;
    },
  },
  modules: {
    admin,
    system,
    cart,
    product,
  },
});
