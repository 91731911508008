export default {
  namespaced: true,
  state: {
    temp_product: {},
  },
  mutations: {
    TEMP_PRODUCT(state, payload) {
      state.temp_product = payload;
    },
  },
  actions: {
    /* 顯示 product modal 彈窗 */
    async toggle_modal(context) {
      $('#product_modal').modal('toggle');
    },
  },
  getters: {
    temp_product(state) {
      return state.temp_product;
    },
  },
};
